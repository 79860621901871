export default [
  {
    path: '/payment-lists',
    name: 'payment-lists',
    component: () => import('@/views/pages/management/payment/paymentLists/Lists.vue'),
    meta: {
      pageTitle: 'รายการโอนเงิน',
      breadcrumb: [
        {
          text: 'แจ้งการโอนเงินจากลูกค้า',
        },
        {
          text: 'รายการโอนเงิน',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-detail/:id',
    name: 'payment-detail',
    component: () => import('@/views/pages/management/payment/paymentDetail/main.vue'),
    meta: {
      pageTitle: 'รายละเอียด',
      breadcrumb: [
        {
          text: 'แจ้งการโอนเงินจากลูกค้า',
        },
        {
          text: 'รายการโอนเงิน',
          to: '/payment-lists'
        },
        {
          text: 'รายละเอียด',
          active: true,
        },
      ],
    },
  },
  {
    path: '/withdraw-lists',
    name: 'withdraw-lists',
    component: () => import('@/views/pages/management/withdraw/withdrawLists/Lists.vue'),
    meta: {
      pageTitle: 'รายการถอนเงิน',
      breadcrumb: [
        {
          text: 'แจ้งการถอนเงิน',
        },
        {
          text: 'รายการถอนงิน',
          active: true,
        },
      ],
    },
  },
]
